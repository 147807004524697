<template>
    <div class="audio-player">
        <v-row justify="center" class="voice-record-strip" align="center">
            <v-col cols="2" class="d-flex" align="center">
                <v-btn
                    fab
                    dark
                    color="orange"
                    small
                    @click="togglePlay"
                >
                    <v-icon v-if="isPlaying">
                        mdi-pause
                    </v-icon>
                    <v-icon v-else>
                        mdi-play
                    </v-icon>
                </v-btn>
            </v-col>

            <v-col cols="6" class="d-flex align-center justify-center">
                <div v-if="isPlaying">
                    <img :src="require('@/assets/animations/lottie-audio-waves.gif')" alt="Playing..." height="60px" width="100px" />
                </div>
                <icon-audio-wave v-else />
            </v-col>

            <v-col cols="4" class="d-flex align-center">
                <span>{{ formattedTime }}</span>
                <v-btn
                    fab
                    dark
                    color="orange ml-4"
                    small
                    @click="$emit('audioDelete')"
                >
                    <v-icon>
                        mdi-delete-circle
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <audio ref="audio" :src="audioSrc" @timeupdate="updateTime" @loadedmetadata="loadedMeta" @ended="isPlaying = false"></audio>
    </div>
</template>
  
<script>
    import IconAudioWave from "@/assets/images/icons/audio-wave.svg?inline";
    import LottieAnimation from "@/components/app/common/LottieAnimation";

    export default {
		name: "WaveAudioPlayer",
        props: {
            audioSrc: {
	    		type: String,
		        required: true
	        },
        },
        data() {
            return {
                isPlaying: false,
                currentTime: 0,
                duration: 0,
                player: null,
            };
        },
        computed: {
            formattedTime() {
                return `${this.formatTime(this.duration)}`;
            },
        },
        methods: {
            loadedMeta() {
          		this.duration = this.player.duration
          	},
            togglePlay() {
                if(this.player.paused) {
					this.isPlaying = true
					this.player.play()
				} else {
					this.isPlaying = false
					this.player.pause()
				}
            },
            updateTime() {
                this.currentTime = this.player.currentTime;
                this.duration = this.player.duration || 0;
            },
            formatTime(seconds) {
                const min = Math.floor(seconds / 60);
                const sec = Math.floor(seconds % 60).toString().padStart(2, "0");
                return `${min}:${sec}`;
            },
        },
        components: {
		    IconAudioWave,
            LottieAnimation,
	    },
        mounted() {
			this.player = this.$refs.audio
        }
    };
</script>
  
<style scoped>
    .audio-player {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .voice-record-strip { border-radius: 6px; background-color: rgba(237, 237, 237, 0.1); border-color: rgba(237, 237, 237, 0.1); box-shadow: 0px 3px 10px -2px rgba(85, 85, 85, 0.08), 0px 2px 20px 0px rgba(85, 85, 85, 0.06), 0px 1px 30px 0px rgba(85, 85, 85, 0.03); }
</style>
  